import {inject, Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import * as SpinnerActions from '../../../store/spinner/spinner.actions';
import {ToastAlertSignalStore} from '../../../store/alert/toast-alert.signal-store';
import {ToastAlert} from '../../../model/toast-alert';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class GeneralInterceptor implements HttpInterceptor {

  errorCodeWhitelist: string[] = ['error.mail.recipients', 'error.mail.buyer.recipients',
    'error.mail.custodian.recipients','error.mail.buyerAndCustodian.recipients'];

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private translate: TranslateService,
              private store: Store<fromRoot.AppState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let ok: string;
    let httpError: HttpErrorResponse;

    return next.handle(req)
      .pipe(
        tap(
          event => ok = event instanceof HttpResponse ? 'succeeded' : '',
          error => {
            httpError = error;
          }
        ),
        finalize(() => {
          if (httpError && httpError.error) {
            const errors = httpError.error as any;
            if (!this.isWhitelistErrorCode(errors)) {
              this.fallbackDisableSpinner();
              this.handleErrors(errors, httpError);
            }
          }
        })
      );
  }

  handleErrors(errors, httpError) {
    switch (true) {
    case errors.errors:
      this.handleDetailedErrors(errors);
      break;
    case errors.globalErrors:
      this.handleGlobalErrors(errors);
      break;
    default:
      this.handleHttpError(httpError);
      break;
    }
  }

  handleDetailedErrors(errors) {
    for (const err of errors.errors) {
      this.toastAlertStore.addToastAlert(new ToastAlert('danger', this.translate.instant(err.code) +
          ' (Support-ID: ' + errors.reference + ')'));
    }
  }

  handleGlobalErrors(errors) {
    for (const err of errors.globalErrors) {
      this.toastAlertStore.addToastAlert(new ToastAlert('danger', this.translate.instant(err.code) +
          ' (Support-ID: ' + errors.reference + ')'));
    }
  }

  handleHttpError(httpError) {
    this.toastAlertStore.addToastAlert(new ToastAlert('danger',  httpError.error.message));
  }

  /**
   * just in case the spinner state was set true directly without using the spinner service
   */
  fallbackDisableSpinner(): void {
    this.store.dispatch(new SpinnerActions.UpdateLoadingAction(false));
  }

  /**
   * Checks if the error code of the error is on the whitelist of error Codes
   * @param error the error you want to check
   */
  isWhitelistErrorCode(error: any) {
    return this.errorCodeWhitelist.includes(error.code);
  }
}
