import {Inject, Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {WINDOW} from '../../../../window-providers';
import * as SpinnerActions from '../../../../shared/store/spinner/spinner.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../shared/store/app.reducers';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {

  errorCodeWhitelist: string[] = ['error.mail.recipients', 'error.mail.buyer.recipients',
    'error.mail.custodian.recipients','error.mail.buyerAndCustodian.recipients'];


  constructor(
  private router: Router,
  private store: Store<fromRoot.AppState>,
  @Inject(WINDOW) private window: Window) {}
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let ok: string;
    let errorStatus: number;
    let errorCode: string;


    // regex to match offerDetails
    const offerDetailRegex = /\/offer\/\d+$/;
    if (!window.location.pathname.match(offerDetailRegex)) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(
        tap(
          event => ok = event instanceof HttpResponse ? 'succeeded' : '',
          error => {
            errorStatus = error.status;
            errorCode = error instanceof HttpErrorResponse ? error.error.code : 'undefinded';
            ok = 'failed';
          }
        ),
        finalize(() => {
          if (ok === 'failed' && errorStatus === 404 && !this.isWhitelistErrorCode(errorCode)) {
            this.fallbackDisableSpinner();
            this.router.navigateByUrl(`error/${errorStatus}`);
          }
        })
      );
  }

  /**
   * just in case the spinner state was set true directly without using the spinner service
   */
  fallbackDisableSpinner(): void {
    this.store.dispatch(new SpinnerActions.UpdateLoadingAction(false));
  }

  /**
   * Checks if the error code of the error is on the whitelist of error Codes
   * @param error the error you want to check
   */
  isWhitelistErrorCode(error: string) {
    return this.errorCodeWhitelist.includes(error);
  }
}
