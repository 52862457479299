import {Component, inject} from '@angular/core';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ToastAlertSignalStore} from '../../shared/store/alert/toast-alert.signal-store';

@Component({
  standalone: true,
  selector: 'ucs-toast-container',
  templateUrl: './toast-container.component.html',
  imports: [
    NgbToastModule, SharedModule
  ],
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent {

  readonly toastAlertStore = inject(ToastAlertSignalStore);
}
